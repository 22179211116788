import React, { Component } from 'react';
import { createPortal } from 'react-dom';
import PT from 'prop-types';
import Calendar from 'react-calendar';
import MaskedInput from 'react-input-mask';
import dayjs from 'dayjs';
import dayjsLocale from 'dayjs/locale/ru';
import { range } from 'lodash';
import formatMonthYear, { parseDate } from '../../utils/formatMonthYear';

const minDate = new Date(1900, 0, 1);
const maxDate = new Date();

class DatePickerFG extends Component {
  state = {
    pickerShown: false,
    curDate: parseDate('', false),
  };

  inputref = null;

  static getDerivedStateFromProps(nextProps, prevState) {
    const { value: oldValue } = prevState;
    const { value: newValue } = nextProps;
    let curDate = parseDate(newValue, false);
    if (curDate.isAfter(dayjs())) {
      curDate = dayjs();
    }
    if (curDate.isBefore(dayjs('1900-01-01'))) {
      curDate = dayjs('1900-01-01');
    }
    if (oldValue !== newValue) {
      return {
        value: newValue,
        curDate,
      };
    }
    return null;
  }

  componentDidMount() {
    global.window.addEventListener('resize', this.updateDimensions);
  }

  componentWillUnmount() {
    global.window.removeEventListener('resize', this.updateDimensions);
  }

  overlayClick = () => {
    if (this.state.pickerShown) {
      this.setState({
        pickerShown: false,
        bottom: 0,
        left: 0,
      });
    }
  }

  updateDimensions = () => {
    let bottom = 0;
    let left = 0;
    if (this.inputref) {
      const {
        x, y, width, height,
      } = this.inputref.getBoundingClientRect();
      bottom = global.window.innerHeight - height - y;
      left = x + width;
    }
    this.setState({
      bottom,
      left,
    });
  }

  onClick = () => {
    this.setState(prevState => ({
      pickerShown: !prevState.pickerShown,
    }));
    this.updateDimensions();
  }

  onChange = (val) => {
    const date = dayjs(val);
    const { onChangeDate, name } = this.props;
    this.setState({
      curDate: date,
    });
    onChangeDate(name, date.format('DD.MM.YYYY'));
    this.setState({
      pickerShown: false,
    });
  }

  setRef = (c) => {
    this.inputref = c;
  }

  onChangeMonth = (e) => {
    const { curDate } = this.state;
    this.setState({
      curDate: dayjs(curDate).set('month', e.target.value),
    });
  }

  onChangeYear = (e) => {
    const { curDate } = this.state;
    this.setState({
      curDate: dayjs(curDate).set('year', e.target.value),
    });
  }

  render() {
    const {
      error,
      touched,
      value,
      name,
      label,
      onChangeText,
    } = this.props;
    const {
      pickerShown,
      bottom,
      left,
    } = this.state;

    let curDate = dayjs(this.state.curDate);
    if (curDate.isAfter(dayjs())) {
      curDate = dayjs();
    }
    if (curDate.isBefore(dayjs('1900-01-01'))) {
      curDate = dayjs('1900-01-01');
    }

    let { months } = dayjsLocale;
    if (curDate.year() === dayjs().year()) {
      months = dayjsLocale.months.slice(0, dayjs().month() + 1);
    }


    return (
      <div className="appointment-form-group">
        {error && touched ? (
          <span className="appointment-errors">{error}</span>
        ) : (
          <label htmlFor={name} className="appointment-label">{label}</label>
        )}
        <div className="appointment-picker">
          {pickerShown && createPortal((
            <>
              <div className="appointment-calendar-overlay" onClick={this.overlayClick}></div>
              <div className="appointment-calendar" style={{ bottom, left }}>
                <div className="appointment-calendar-select-wrapper">
                  <select className="appointment-calendar-select" value={curDate.month()} onChange={this.onChangeMonth}>
                    {months.map((month, index) => (
                      <option key={index} value={index}>{month}</option>
                    ))}
                  </select>
                  <select className="appointment-calendar-select" value={curDate.year()} onChange={this.onChangeYear}>
                    {range(1900, dayjs().year() + 1).map(year => (
                      <option key={year} value={year}>{year}</option>
                    ))}
                  </select>
                </div>
                <Calendar
                  onChange={this.onChange}
                  prevLabel={<i className="icon icon-arrow"></i>}
                  nextLabel={<i className="icon icon-arrow"></i>}
                  minDate={minDate}
                  maxDate={maxDate}
                  formatMonthYear={formatMonthYear('MMMM YYYY')}
                  value={curDate.toDate()}
                />
              </div>
            </>
          ), global.document.getElementById('appointment-calendar-wrapper'))}
        </div>
        <div className="appointment-input appointment-picker-input">
          <MaskedInput
            mask="99.99.9999"
            inputRef={this.setRef}
            id={name}
            name={name}
            value={value}
            onChange={onChangeText} />
          <i
            className="icon icon-calendar"
            onClick={this.onClick}
          ></i>
        </div>
      </div>
    );
  }
}

DatePickerFG.propTypes = {
  error: PT.string,
  touched: PT.bool,
  value: PT.string,
  name: PT.string.isRequired,
  label: PT.string.isRequired,
  onChangeDate: PT.func.isRequired,
  onChangeText: PT.func.isRequired,
};

DatePickerFG.defaultProps = {
  error: '',
  touched: false,
  value: '',
};

export default DatePickerFG;

import { pick } from 'lodash';
import * as types from '../actions/actionsMain';
import * as authTypes from '../actions/actionsAuth';
import * as regTypes from '../actions/actionsReg';

const initialSelected = {
  selectedDepartment: null,
  selectedDirection: null,
  selectedService: null,
  selectedDate: null,
  selectedTime: null,
  selectedDoctor: null,
  selectedPhone: null,
  selectedUser: null,
};

export const initialState = {
  token: '',
  currentStep: 0,
  currentTitle: 'Записаться на прием',
  ...initialSelected,
};

const incrementStep = state => ({
  currentStep: state.currentStep + 1,
  currentTitle: state.currentStep + 1 === 9 ? 'Подтвердите запись' : 'Записаться на прием',
});

const resetStep = (state, step) => ({
  ...state,
  currentStep: step,
  currentTitle: step === 9 ? 'Подтвердите запись' : 'Записаться на прием',
  ...pick(initialSelected, Object.keys(initialSelected).slice(step)),
});

const reducerMain = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_RESET_STEP:
      return {
        ...state,
        ...resetStep(state, action.data),
      };
    case types.SET_INCREMENT_STEP:
      return {
        ...state,
        ...incrementStep(state),
      };
    case types.SET_SELECTED_DEPARTMENT:
      return {
        ...state,
        selectedDepartment: action.data,
        ...incrementStep(state),
      };
    case types.SET_SELECTED_DEPARTMENT_CHILD:
      return {
        ...state,
        selectedDirection: action.data,
        ...incrementStep(state),
      };
    case types.SET_SELECTED_SERVICE:
      return {
        ...state,
        selectedService: action.data,
        ...incrementStep(state),
      };
    case types.SET_SELECTED_DATE:
      return {
        ...state,
        selectedDate: action.data,
        ...incrementStep(state),
      };
    case types.SET_SELECTED_TIME:
      return {
        ...state,
        selectedTime: action.data,
        ...incrementStep(state),
      };
    case types.SET_SELECTED_DOCTOR:
      return {
        ...state,
        selectedDoctor: action.data,
      };
    case types.SET_SELECTED_USER:
    case regTypes.SET_REQUEST_REGISTER_SUCCESS:
      return {
        ...state,
        selectedUser: action.data,
        ...incrementStep(state),
      };
    case authTypes.SET_REQUEST_SEND_SMS_SUCCESS:
      return {
        ...state,
        selectedPhone: action.data,
      };
    case authTypes.SET_REQUEST_SEND_CODE_SUCCESS:
      return {
        ...state,
        token: action.data?.token,
      };
    default:
      return state;
  }
};

export default reducerMain;

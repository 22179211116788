import { connect } from 'react-redux';
import { getDepartmentsRequest } from '../actions/actionsDepartments';
import { setSelectedDepartment, setResetStep } from '../actions/actionsMain';
import DepartmentComponent from '../components/DepartmentComponent';

const mapStateToProps = ({
  reducerMain: { selectedDepartment },
  reducerDepartments: { isLoading, departments },
}) => ({
  isLoading,
  departments,
  selectedDepartment,
});

const mapActionToProps = { getDepartments: getDepartmentsRequest, setDepartment: setSelectedDepartment, resetStep: setResetStep };

export default connect(mapStateToProps, mapActionToProps)(DepartmentComponent);

import * as types from '../actions/actionsDirections';

export const initialState = {
  isLoading: false,
  error: '',
  success: false,
  directions: [],
};

const reducerDirections = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_REQUEST_DEPARTMENT_CHILDREN:
      return {
        ...initialState,
        isLoading: true,
      };
    case types.SET_REQUEST_DEPARTMENT_CHILDREN_ERROR:
      return {
        ...state,
        error: action.data,
        isLoading: false,
      };
    case types.SET_REQUEST_DEPARTMENT_CHILDREN_SUCCESS:
      return {
        ...state,
        directions: action.data,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default reducerDirections;

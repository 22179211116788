import { getDepartments } from '../utils/serviceREST';
import { setGlobalErr } from './actionsErr';

export const SET_REQUEST_DEPARTMENTS = 'SET_REQUEST_DEPARTMENTS';
export const SET_REQUEST_DEPARTMENTS_ERROR = 'SET_REQUEST_DEPARTMENTS_ERROR';
export const SET_REQUEST_DEPARTMENTS_SUCCESS = 'SET_REQUEST_DEPARTMENTS_SUCCESS';

export const setRequestDepartments = () => ({ type: SET_REQUEST_DEPARTMENTS });
export const setRequestDepartmentsError = data => ({ type: SET_REQUEST_DEPARTMENTS_ERROR, data });
export const setRequestDepartmentsSuccess = data => ({ type: SET_REQUEST_DEPARTMENTS_SUCCESS, data });

export const getDepartmentsRequest = () => async (dispatch) => {
  dispatch(setRequestDepartments());
  const result = await getDepartments();
  const { data, status } = result || {};
  if (status === 200 && data?.statusCode === 0) {
    dispatch(setRequestDepartmentsSuccess(data?.response));
    return;
  }
  dispatch(setGlobalErr({
    message: data?.statusMessage,
    action: 'getDepartmentsRequest',
    params: [],
  }));
  dispatch(setRequestDepartmentsError(data?.statusMessage));
};

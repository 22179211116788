import { connect } from 'react-redux';
import { setSelectedDoctor, setResetStep } from '../actions/actionsMain';
import DoctorComponent from '../components/DoctorComponent';

const mapStateToProps = ({
  reducerMain: { selectedDoctor },
  reducerTimetables: { doctors },
}) => ({
  selectedDoctor,
  doctors,
});

const mapActionToProps = { setDoctor: setSelectedDoctor, resetStep: setResetStep };

export default connect(mapStateToProps, mapActionToProps)(DoctorComponent);

import React from 'react';

const FinishComponent = () => (
  <div className="appointment-finsh">
    <i className="icon icon-writing"></i>
    <span className="appointment-title appointment-finsh-title">
      Запись на прием прошла успешно
    </span>
  </div>
);

export default FinishComponent;

import * as types from '../actions/actionsErr';

export const initialState = {
  message: '',
  action: null,
  params: [],
};

const reducerDepartments = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_GLOBAL_ERR:
      return {
        ...state,
        ...action.data,
      };
    case types.RESET_GLOBAL_ERR:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default reducerDepartments;

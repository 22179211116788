import React, { Component } from 'react';
import PT from 'prop-types';
import CSSTransitionGroup from 'react-addons-css-transition-group';
import { isEqual } from 'lodash';

class SelectBoxComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      q: '',
      filteredItems: props.items,
    };
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.items, this.props.items)) {
      this.filterItems();
    }
  }

  filterItems() {
    const { items } = this.props;
    const { q } = this.state;
    const filteredItems = items.filter(item => item.name.toLowerCase().indexOf(q.toLowerCase()) !== -1);

    this.setState({
      filteredItems,
    });
  }

  onSearchChange = (e) => {
    const { value: q } = e?.target;
    this.setState({ q }, this.filterItems);
  }

  onClickSelected = () => {
    if (this.props.selected) {
      this.props.onClickSelected();
    }
  }

  render() {
    const {
      showSearch,
      onSelect,
      title,
      icon,
      selected,
    } = this.props;
    const { q, filteredItems } = this.state;
    return (
      <div className={`select-box ${selected ? 'select-box-active' : ''}`}>
        <div className="select-box-title" onClick={this.onClickSelected}>
          {icon && (<i className={`select-box-icon icon icon-${icon}`}></i>)}
          <span className="select-box-name">{ !selected ? title : selected.name }</span>
          {selected?.price && (<span className="select-box-price">
          { selected.price }
          <i className="icon icon-rouble"></i>
        </span>)}
        </div>
        {(showSearch && !selected) && (
          <div className="select-box-search">
            <i className="select-box-icon icon icon-poisk"></i>
            <input
              type="text"
              placeholder="Поиск услуги"
              onChange={this.onSearchChange}
              value={q}
            />
          </div>
        )}
        <div className="appointment-content-wrapper">
          <CSSTransitionGroup
            transitionName="appointment-slide"
            transitionAppear={true}
            transitionAppearTimeout={500}
            transitionEnterTimeout={500}
            transitionLeaveTimeout={300}
          >
            {!selected && (
                <div className="select-box-items appointment-content">
                  {filteredItems.map(item => (
                    <div className="select-box-item" key={item.id} onClick={() => onSelect(item)}>
                      <span className="select-box-name">{ item.name }</span>
                      {item.price && (<span className="select-box-price">
                        { item.price }
                        <i className="icon icon-rouble"></i>
                      </span>)}
                    </div>
                  ))}
                </div>
            )}
          </CSSTransitionGroup>
        </div>
      </div>
    );
  }
}

SelectBoxComponent.propTypes = {
  onClickSelected: PT.func.isRequired,
  title: PT.string.isRequired,
  icon: PT.string,
  showSearch: PT.bool,
  onSelect: PT.func.isRequired,
  items: PT.arrayOf(PT.shape({
    id: PT.oneOfType([PT.number, PT.string]).isRequired,
    name: PT.string.isRequired,
  })).isRequired,
  selected: PT.object,
};

SelectBoxComponent.defaultProps = {
  showSearch: false,
  selected: null,
};

export default SelectBoxComponent;

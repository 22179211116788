import React, { Component, Fragment } from 'react';
import PT from 'prop-types';
import StepsHeaderComponent from './common/StepsHeaderComponent';
import DepartmentContainer from '../containers/DepartmentContainer';
import DirectionContainer from '../containers/DirectionContainer';
import ServiceContainer from '../containers/ServiceContainer';
import DateContainer from '../containers/DateContainer';
import TimeContainer from '../containers/TimeContainer';
import DoctorContainer from '../containers/DoctorContainer';
import PhoneContainer from '../containers/PhoneContainer';
import CodeContainer from '../containers/CodeContainer';
import UserContainer from '../containers/UserContainer';
import OrderContainer from '../containers/OrderContainer';
import FinishComponent from './FinishComponent';
import ErrorHandlerContainer from '../containers/ErrorHandlerContainer';
import steps from '../constants/steps';
import WelcomeComponent from './WelcomeComponent';

class MainComponent extends Component {
  state = {
    appointmentStarted: false,
  };

  startAppointment = () => {
    this.setState({
      appointmentStarted: true,
    });
  };

  render() {
    const {
      currentTitle,
      currentStep,
      selectedDoctor,
      nextStep,
      resetStep,
    } = this.props;

    const { appointmentStarted } = this.state;

    return (<Fragment>
      <div className="appointment-backdrop" />
      <div id="appointment-calendar-wrapper" className="appointment-calendar-wrapper" />
      <div className="appointment-modal-wrapper">
        <img src="/sites/default/files/close.svg" className="closewin dc" />
        {!appointmentStarted ? (
          <WelcomeComponent
            startAppointment={this.startAppointment}
          />
        ) : (
          <Fragment>
            {(currentStep <= 9) && (
              <Fragment>
              <div className="row">
                <div className="col-xs-12">
                  <span className="appointment-title">{ currentTitle }</span>
                </div>
              </div>
              <StepsHeaderComponent
                steps={steps}
                currentStep={currentStep}
                resetStep={resetStep}
              />
              </Fragment>
            )}
            <div className="appointment-modal">
              {(currentStep >= 0 && currentStep < 6) && (
                <DepartmentContainer />
              )}
              {(currentStep >= 1 && currentStep < 6) && (
                <DirectionContainer />
              )}
              {(currentStep >= 2 && currentStep < 6) && (
                <ServiceContainer />
              )}
              {(currentStep >= 3 && currentStep < 6) && (
                <DateContainer />
              )}
              {(currentStep >= 4 && currentStep < 6) && (
                <TimeContainer />
              )}
              {(currentStep >= 5 && currentStep < 6) && (
                <DoctorContainer />
              )}
              {(currentStep >= 5 && currentStep < 6 && selectedDoctor) && (
                <button className="btn appointment-btn" onClick={nextStep}>Далее</button>
              )}
              {(currentStep === 6) && (
                <PhoneContainer />
              )}
              {(currentStep === 7) && (
                <CodeContainer />
              )}
              {(currentStep === 8) && (
                <UserContainer />
              )}
              {(currentStep === 9) && (
                <OrderContainer />
              )}
              {(currentStep === 10) && (
                <FinishComponent />
              )}
              <ErrorHandlerContainer />
            </div>
          </Fragment>
        )}
      </div>
    </Fragment>);
  }
}

MainComponent.propTypes = {
  currentTitle: PT.string,
  currentStep: PT.number,
  nextStep: PT.func.isRequired,
  resetStep: PT.func.isRequired,
  selectedDoctor: PT.object,
};

MainComponent.defaultProps = {
  currentTitle: '',
  currentStep: 0,
  selectedDoctor: null,
};

export default MainComponent;

import React from 'react';
import PT from 'prop-types';

const ErrorHandlerComponent = ({
  message,
  action,
  params,
  currentStep,
  resetStep,
  resetErr,
  ...props
}) => (message !== '' ? (
  <div className="appointment-error-handler">
    <span className="appointment-error-message">{ message }</span>
    <div className="appointment-error-handler-buttons">
        <button
        className="btn appointment-btn appointment-error-retry"
        onClick={() => {
          resetErr();
          props[action](...params);
        }}>Повторить</button>
        {currentStep !== 0 && (
            <button
            className="btn appointment-btn appointment-error-cancel"
            onClick={() => {
              resetErr();
              resetStep(currentStep - 1);
            }}>Отмена</button>
        )}
        </div>
  </div>
) : null);

ErrorHandlerComponent.propTypes = {
  currentStep: PT.number.isRequired,
  resetStep: PT.func.isRequired,
  resetErr: PT.func.isRequired,
  message: PT.string,
  action: PT.func,
  params: PT.array,
};

ErrorHandlerComponent.defaultProps = {
  message: '',
  action: null,
  params: [],
};

export default ErrorHandlerComponent;

export const SET_INCREMENT_STEP = 'SET_INCREMENT_STEP';
export const SET_RESET_STEP = 'SET_RESET_STEP';
export const SET_SELECTED_DEPARTMENT = 'SET_SELECTED_DEPARTMENT';
export const SET_SELECTED_DEPARTMENT_CHILD = 'SET_SELECTED_DEPARTMENT_CHILD';
export const SET_SELECTED_SERVICE = 'SET_SELECTED_SERVICE';
export const SET_SELECTED_DATE = 'SET_SELECTED_DATE';
export const SET_SELECTED_TIME = 'SET_SELECTED_TIME';
export const SET_SELECTED_DOCTOR = 'SET_SELECTED_DOCTOR';
export const SET_SELECTED_USER = 'SET_SELECTED_USER';

export const setResetStep = data => ({ type: SET_RESET_STEP, data });
export const setIncrementStep = data => ({ type: SET_INCREMENT_STEP, data });
export const setSelectedDepartment = data => ({ type: SET_SELECTED_DEPARTMENT, data });
export const setSelectedDepartmentChild = data => ({ type: SET_SELECTED_DEPARTMENT_CHILD, data });
export const setSelectedService = data => ({ type: SET_SELECTED_SERVICE, data });
export const setSelectedDate = data => ({ type: SET_SELECTED_DATE, data });
export const setSelectedTime = data => ({ type: SET_SELECTED_TIME, data });
export const setSelectedDoctor = data => ({ type: SET_SELECTED_DOCTOR, data });
export const setSelectedUser = data => ({ type: SET_SELECTED_USER, data });

import { getDepartments } from '../utils/serviceREST';
import { setGlobalErr } from './actionsErr';

export const SET_REQUEST_DEPARTMENT_CHILDREN = 'SET_REQUEST_DEPARTMENT_CHILDREN';
export const SET_REQUEST_DEPARTMENT_CHILDREN_ERROR = 'SET_REQUEST_DEPARTMENT_CHILDREN_ERROR';
export const SET_REQUEST_DEPARTMENT_CHILDREN_SUCCESS = 'SET_REQUEST_DEPARTMENT_CHILDREN_SUCCESS';

export const setRequestDepartmentChildren = () => ({ type: SET_REQUEST_DEPARTMENT_CHILDREN });
export const setRequestDepartmentChildrenError = data => ({ type: SET_REQUEST_DEPARTMENT_CHILDREN_ERROR, data });
export const setRequestDepartmentChildrenSuccess = data => ({ type: SET_REQUEST_DEPARTMENT_CHILDREN_SUCCESS, data });

export const getDepartmentChildrenRequest = (parentId = null) => async (dispatch) => {
  dispatch(setRequestDepartmentChildren());
  const result = await getDepartments({ parentId });
  const { data, status } = result || {};
  if (status === 200 && data?.statusCode === 0) {
    dispatch(setRequestDepartmentChildrenSuccess(data?.response));
    return;
  }
  dispatch(setGlobalErr({
    message: data?.statusMessage,
    action: 'getDepartmentChildrenRequest',
    params: [parentId],
  }));
  dispatch(setRequestDepartmentChildrenError(data?.statusMessage));
};

import React, { Component } from 'react';
import PT from 'prop-types';
import { Formik } from 'formik';
import MaskedInput from 'react-input-mask';
import Cookies from 'js-cookie';
import LoaderComponent from './common/LoaderComponent';

const phoneMask = /\+7\(9\d{2}\)\s\d{3}-\d{2}-\d{2}/;
class PhoneComponent extends Component {
  state = {
    success: false,
  };

  initialValues = {
    appointmentPhone: '',
  };

  setSubmitting = null;

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.success !== prevState.success) {
      return { success: nextProps.success };
    }
    return null;
  }

  componentDidMount() {
    if (Cookies.get('token')) {
      this.props.nextStep();
      this.props.nextStep();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.success && this.state.success) {
      this.setSubmitting(false);
      this.props.nextStep();
    }
  }

  validate = (values) => {
    const errors = {};
    if (!values.appointmentPhone) {
      errors.appointmentPhone = 'Заполните поле';
    } else if (!phoneMask.test(values.appointmentPhone)) {
      errors.appointmentPhone = 'Проверьте правильность ввода номера';
    }
    return errors;
  }

  onSubmit = (values, { setSubmitting }) => {
    this.setSubmitting = setSubmitting;
    this.props.sendSms(values.appointmentPhone);
  }

  render() {
    return (
      <Formik
        initialValues={this.initialValues}
        validate={this.validate}
        onSubmit={this.onSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting,
        }) => (
          <form
          className="appointment-content appointment-form appointment-auth-phone" onSubmit={handleSubmit}>
            {isSubmitting && (<LoaderComponent />)}
            <span className="appointment-form-title">
              Для записи на прием введите номер телефона
            </span>
              <div className="appointment-form-group">
                {errors?.appointmentPhone && touched?.appointmentPhone ? (
                  <span className="appointment-errors">{errors?.appointmentPhone}</span>
                ) : (
                  <label htmlFor="appointmentPhone" className="appointment-label">Номер телефона</label>
                )}
                <div className="appointment-input">
                  <i className="icon icon-phone"></i>
                  <MaskedInput
                    mask="+7(\999) 999-99-99"
                    type="text"
                    id="appointmentPhone"
                    name="appointmentPhone"
                    onChange={handleChange}
                    value={values.appointmentPhone} />
                </div>
              </div>
              <input
                className="btn appointment-btn"
                type="submit"
                disabled={isSubmitting}
                value="Получить код" />
            <span className="appointment-hint">Нажимая на кнопку, вы даете согласие на <br/> обработку персональных данных</span>
          </form>
        )}
      </Formik>
    );
  }
}

PhoneComponent.propTypes = {
  isLoading: PT.bool.isRequired,
  error: PT.string.isRequired,
  success: PT.bool.isRequired,
  sendSms: PT.func.isRequired,
  nextStep: PT.func.isRequired,
};

export default PhoneComponent;

import React, { Component } from 'react';
import PT from 'prop-types';
import SelectBoxComponent from './common/SelectBoxComponent';
import LoaderComponent from './common/LoaderComponent';

class DepartmentComponent extends Component {
  componentDidMount() {
    const { getDepartments, selectedDepartment } = this.props;
    if (!selectedDepartment) {
      getDepartments();
    }
  }

  resetStep = () => {
    this.props.resetStep(0);
  }

  render() {
    const {
      isLoading,
      departments,
      selectedDepartment,
      setDepartment,
    } = this.props;

    if (isLoading) {
      return (<LoaderComponent />);
    }

    return (
      <div className="department-select-box">
        <SelectBoxComponent
          icon="stethoscope"
          title="Отделение"
          showSearch={departments.length > 11}
          selected={selectedDepartment}
          items={departments}
          onSelect={setDepartment}
          onClickSelected={this.resetStep}
        />
      </div>
    );
  }
}

DepartmentComponent.propTypes = {
  isLoading: PT.bool.isRequired,
  getDepartments: PT.func.isRequired,
  resetStep: PT.func.isRequired,
  departments: PT.array.isRequired,
  setDepartment: PT.func.isRequired,
  selectedDepartment: PT.object,
};
DepartmentComponent.defaultProps = {
  selectedDepartment: null,
};

export default DepartmentComponent;

import Cookies from 'js-cookie';

import store from '../store';
import { setResetStep } from '../actions/actionsMain';

const errorHandler = (response) => {
  if (response.status === 401 || response.status === 403) {
    Cookies.remove('token');
    store.dispatch(setResetStep(6));
  }
};

export default errorHandler;

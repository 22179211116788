import React from 'react';
import PT from 'prop-types';

const FormGroup = ({
  error,
  touched,
  value,
  children,
  name,
  label,
  icon,
  onChange,
}) => (
  <div className="appointment-form-group">
    {error && touched ? (
      <span className="appointment-errors">{error}</span>
    ) : (
      <label htmlFor={name} className="appointment-label">
        {icon && (<i className={`icon icon-${icon}`}></i>)}
        {label}
      </label>
    )}
    <div className="appointment-input">
    {children ? (
      <div className="input-like">
        {children}
      </div>
    ) : (
        <input
          id={name}
          name={name}
          onChange={onChange}
          value={value}
        />
    )}
    </div>
  </div>
);

FormGroup.propTypes = {
  error: PT.string,
  touched: PT.bool,
  value: PT.string,
  children: PT.node,
  icon: PT.string,
  name: PT.string.isRequired,
  label: PT.string.isRequired,
  onChange: PT.func.isRequired,
};

FormGroup.defaultProps = {
  error: '',
  touched: false,
  value: '',
  children: null,
  icon: '',
};

export default FormGroup;

import { connect } from 'react-redux';
import { setIncrementStep } from '../actions/actionsMain';
import { postOrderRequest } from '../actions/actionsOrder';
import OrderComponent from '../components/OrderComponent';

const mapStateToProps = ({
  reducerMain: {
    selectedDepartment,
    selectedDirection,
    selectedService,
    selectedDate,
    selectedTime,
    selectedDoctor,
    selectedUser,
  },
  reducerOrder: {
    isLoading,
    success,
  },
}) => ({
  selectedDepartment,
  selectedDirection,
  selectedService,
  selectedDate,
  selectedTime,
  selectedDoctor,
  selectedUser,
  isLoading,
  success,
});

const mapActionToProps = { createOrder: postOrderRequest, nextStep: setIncrementStep };

export default connect(mapStateToProps, mapActionToProps)(OrderComponent);

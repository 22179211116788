import Cookies from 'js-cookie';
import { create } from 'apisauce';

import apiConfig from '../constants/api';
import errorHandler from './errorHandler';

const { baseURL } = apiConfig;

const storedToken = Cookies.get('token');

const api = create({
  baseURL,
  headers: {
    Accept: 'application/json',
    'Content-type': 'application/json',
    Authorization: typeof storedToken === 'string' ? `Bearer ${storedToken}` : null,
  },
  timeout: 10000,
});


export const setTokenToHeaders = (token) => {
  Cookies.set('token', token);
  api.setHeaders({
    Authorization: typeof token === 'string' ? `Bearer ${token}` : null,
  });
};

export const resetToken = () => {
  api.setHeaders({
    Authorization: null,
  });
};

api.addMonitor(errorHandler);

export const getDepartments = (params = {}) => api.get('getDepartments', params);
export const getServices = (params = {}) => api.get('getServices', params);
export const getTimetable = (params = {}) => api.get('getTimetable', params);
export const sendSms = (params = {}) => api.post('sendSms', params);
export const sendCode = (params = {}) => api.post('sendCode', params);
export const getUsers = (params = {}) => api.get('getUsers', params);
export const createUser = (params = {}) => api.post('createUser', params);
export const createOrder = (params = {}) => api.post('createOrder', params);

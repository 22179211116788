const steps = [
  [
    { index: 0, icon: 'stethoscope', name: 'Отделение' },
    { index: 1, icon: 'sheet', name: 'Направление' },
    { index: 2, icon: 'heart', name: 'Услуга' },
    { index: 3, icon: 'calendar', name: 'Дата' },
    { index: 4, icon: 'time', name: 'Время' },
    { index: 5, icon: 'doctor', name: 'Врач' },
  ],
  [
    {
      index: 6, icon: 'phone', name: 'Номер', auth: true,
    },
    {
      index: 6, icon: 'message', name: 'SMS', auth: true,
    },
    { index: 8, icon: 'user', name: 'Анкета' },
    { index: 9, icon: 'mark', name: 'Подтверждение' },
  ],
];

export default steps;

import { connect } from 'react-redux';
import { getTimetablesRequest } from '../actions/actionsTimetables';
import { setSelectedDate, setResetStep } from '../actions/actionsMain';
import DateComponent from '../components/DateComponent';

const mapStateToProps = ({
  reducerMain: { selectedService, selectedDate },
  reducerTimetables: { isLoading, dates },
}) => ({
  isLoading,
  selectedService,
  selectedDate,
  dates,
});

const mapActionToProps = { setDate: setSelectedDate, getTimetables: getTimetablesRequest, resetStep: setResetStep };

export default connect(mapStateToProps, mapActionToProps)(DateComponent);

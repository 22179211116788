import { connect } from 'react-redux';
import { setIncrementStep } from '../actions/actionsMain';
import { postSendSmsRequest } from '../actions/actionsAuth';
import PhoneComponent from '../components/PhoneComponent';

const mapStateToProps = ({
  reducerAuth: {
    sendSms: {
      isLoading, error, success,
    },
  },
}) => ({
  isLoading,
  error,
  success,
});

const mapActionToProps = { sendSms: postSendSmsRequest, nextStep: setIncrementStep };

export default connect(mapStateToProps, mapActionToProps)(PhoneComponent);

import React from 'react';
import PT from 'prop-types';
import CSSTransitionGroup from 'react-addons-css-transition-group';

const onClickTime = (setTime, time) => () => {
  setTime(time);
};

const formatTime = time => time.slice(0, time.length - 3);

const TimeComponent = ({
  times,
  setTime,
  selectedTime,
  resetStep,
}) => (
  <div className="date-select-box">
    <div className={`select-box ${selectedTime && 'select-box-active'}`}>
        <div
        className="select-box-title"
        onClick={() => {
          if (selectedTime) {
            resetStep(4);
          }
        }}
        >
        <i className="select-box-icon icon icon-time"></i>
        <span className="select-box-name">{ !selectedTime ? 'Время' : selectedTime.time.slice(0, 5) }</span>
        </div>
        <div className="appointment-content-wrapper">
        <CSSTransitionGroup
            transitionName="appointment-slide"
            transitionAppear={true}
            transitionAppearTimeout={500}
            transitionEnterTimeout={500}
            transitionLeaveTimeout={300}
        >
            {!selectedTime && (
            <div className="select-box-times appointment-content">
                {times.map(time => (
                <div className="select-box-time" key={time.time} onClick={onClickTime(setTime, time)}>
                    { formatTime(time.time) }
                </div>
                ))}
            </div>
            )}
        </CSSTransitionGroup>
        </div>
    </div>
  </div>
);

TimeComponent.propTypes = {
  resetStep: PT.func.isRequired,
  setTime: PT.func.isRequired,
  times: PT.array.isRequired,
  selectedTime: PT.object,
};
TimeComponent.defaultProps = {
  selectedTime: null,
};

export default TimeComponent;

import { connect } from 'react-redux';
import { setSelectedUser } from '../actions/actionsMain';
import { getUsersRequest } from '../actions/actionsUsers';
import { postCreateUserRequest } from '../actions/actionsReg';
import UserComponent from '../components/UserComponent';

const mapStateToProps = ({
  reducerUsers: { isLoading: isLoadingUsers, users, success: successUsers },
  reducerReg: { isLoading: isLoadingReg },
}) => ({
  isLoadingUsers,
  isLoadingReg,
  successUsers,
  users,
});

const mapActionToProps = {
  getUsers: getUsersRequest,
  createUser: postCreateUserRequest,
  setUser: setSelectedUser,
};

export default connect(mapStateToProps, mapActionToProps)(UserComponent);

import React, { Component } from 'react';
import PT from 'prop-types';
import { parseDate } from '../utils/formatMonthYear';

import LoaderComponent from './common/LoaderComponent';
import SelectUserComponent from './SelectUserComponent';
import AddUserComponent from './AddUserComponent';

class UserComponent extends Component {
  state = {
    adding: false,
    init: false,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.successUsers
        && nextProps.users.length === 0
        && !prevState.init) {
      return {
        adding: true,
        init: true,
      };
    }
    return null;
  }

  componentDidMount() {
    const { getUsers } = this.props;
    getUsers();
  }

  onClickUser = user => () => {
    this.props.setUser(user);
  }

  onAdd = () => {
    this.setState({ adding: true });
  }

  onClickBack = () => {
    this.setState({ adding: false });
  }

  onNext = (values) => {
    const {
      appointmentSurname,
      appointmentName,
      appointmentLastname,
      appointmentBirthday,
    } = values;
    const { createUser } = this.props;
    createUser({
      surname: appointmentSurname,
      name: appointmentName,
      lastname: appointmentLastname,
      birthday: parseDate(appointmentBirthday, false).format('YYYY-MM-DD'),
    });
  }

  render() {
    const { users, isLoadingUsers, isLoadingReg } = this.props;
    const { adding } = this.state;

    const isLoading = isLoadingUsers || isLoadingReg;
    return (
      <div>
        {isLoading && (<LoaderComponent />)}
        {!adding ? (
          <SelectUserComponent
            users={users}
            onClick={this.onClickUser}
            onAdd={this.onAdd}
          />
        ) : (
          <AddUserComponent
            onNext={this.onNext}
            isLoading={isLoadingReg}
            onClickBack={this.onClickBack}
          />
        )}
      </div>
    );
  }
}

UserComponent.propTypes = {
  getUsers: PT.func.isRequired,
  createUser: PT.func.isRequired,
  setUser: PT.func.isRequired,
  users: PT.array.isRequired,
  isLoadingUsers: PT.bool.isRequired,
  isLoadingReg: PT.bool.isRequired,
};

UserComponent.defaultProps = {
  regId: null,
};

export default UserComponent;

import { connect } from 'react-redux';
import { setIncrementStep, setResetStep } from '../actions/actionsMain';
import MainComponent from '../components/MainComponent';

const mapStateToProps = ({
  reducerMain: {
    currentTitle,
    currentStep,
    nextStep,
    selectedDoctor,
  },
}) => ({
  currentTitle,
  currentStep,
  nextStep,
  selectedDoctor,
});

const mapActionToProps = { nextStep: setIncrementStep, resetStep: setResetStep };

export default connect(mapStateToProps, mapActionToProps)(MainComponent);

import { connect } from 'react-redux';
import { getDepartmentChildrenRequest } from '../actions/actionsDirections';
import { setSelectedDepartmentChild, setResetStep } from '../actions/actionsMain';
import DirectionComponent from '../components/DirectionComponent';

const mapStateToProps = ({
  reducerMain: { selectedDepartment, selectedDirection },
  reducerDirections: { isLoading, directions },
}) => ({
  isLoading,
  directions,
  selectedDepartment,
  selectedDirection,
});

const mapActionToProps = { getDirections: getDepartmentChildrenRequest, setDirection: setSelectedDepartmentChild, resetStep: setResetStep };

export default connect(mapStateToProps, mapActionToProps)(DirectionComponent);

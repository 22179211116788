import * as types from '../actions/actionsUsers';

export const initialState = {
  isLoading: false,
  error: '',
  success: false,
  users: [],
};

const reducerDepartments = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_REQUEST_USERS:
      return {
        ...initialState,
        isLoading: true,
      };
    case types.SET_REQUEST_USERS_ERROR:
      return {
        ...state,
        error: action.data,
        isLoading: false,
      };
    case types.SET_REQUEST_USERS_SUCCESS:
      return {
        ...state,
        users: action.data,
        success: true,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default reducerDepartments;

import { find, map, sortBy } from 'lodash';
import dayjs from 'dayjs';
import * as types from '../actions/actionsTimetables';
import * as typesMain from '../actions/actionsMain';

export const initialState = {
  isLoading: false,
  error: '',
  success: false,
  timetables: [],
  dates: [],
  times: [],
  doctors: [],
};

const reducerServices = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_REQUEST_TIMETABLES:
      return {
        ...initialState,
        isLoading: true,
      };
    case types.SET_REQUEST_TIMETABLES_ERROR:
      return {
        ...state,
        error: action.data,
        isLoading: false,
      };
    case types.SET_REQUEST_TIMETABLES_SUCCESS:
      return {
        ...state,
        timetables: action.data,
        dates: map(action.data, ({ date }) => date),
        isLoading: false,
      };
    case typesMain.SET_SELECTED_DATE:
      return {
        ...state,
        times: sortBy(find(state.timetables, item => dayjs(dayjs(action.data).format('YYYY-MM-DD')).isSame(dayjs(item.date)))?.times, 'time') || [],
      };
    case typesMain.SET_SELECTED_TIME:
      return {
        ...state,
        doctors: find(
          state.times,
          item => action.data?.time?.indexOf(item.time) !== -1,
        )
        ?.doctors
        ?.map(doctor => ({
          id: doctor.doctorId,
          name: doctor.doctorName,
        })),
      };
    default:
      return state;
  }
};

export default reducerServices;

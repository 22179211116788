import { createUser } from '../utils/serviceREST';
import { setGlobalErr } from './actionsErr';

export const SET_REQUEST_REGISTER = 'SET_REQUEST_REGISTER';
export const SET_REQUEST_REGISTER_ERROR = 'SET_REQUEST_REGISTER_ERROR';
export const SET_REQUEST_REGISTER_SUCCESS = 'SET_REQUEST_REGISTER_SUCCESS';

export const setRequestRegister = () => ({ type: SET_REQUEST_REGISTER });
export const setRequestRegisterError = data => ({ type: SET_REQUEST_REGISTER_ERROR, data });
export const setRequestRegisterSuccess = data => ({ type: SET_REQUEST_REGISTER_SUCCESS, data });

export const postCreateUserRequest = (params = null) => async (dispatch) => {
  dispatch(setRequestRegister());
  const result = await createUser(params);
  const { data, status } = result || {};
  if (status === 200 && data?.statusCode === 0) {
    dispatch(setRequestRegisterSuccess({
      id: data?.response,
      f: params?.surname,
      i: params?.name,
      o: params?.lastname,
    }));
    return;
  }
  dispatch(setGlobalErr({
    message: data?.statusMessage,
    action: 'postCreateUserRequest',
    params: [params],
  }));
  dispatch(setRequestRegisterError(data?.statusMessage));
};

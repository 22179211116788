import * as types from '../actions/actionsReg';

export const initialState = {
  isLoading: false,
  error: '',
  regId: null,
};

const reducerDepartments = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_REQUEST_REGISTER:
      return {
        ...initialState,
        isLoading: true,
      };
    case types.SET_REQUEST_REGISTER_ERROR:
      return {
        ...state,
        error: action.data,
        isLoading: false,
      };
    case types.SET_REQUEST_REGISTER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        regId: action.data?.id,
      };
    default:
      return state;
  }
};

export default reducerDepartments;

import { connect } from 'react-redux';
import { setIncrementStep } from '../actions/actionsMain';
import { postSendSmsRequest, postSendCodeRequest } from '../actions/actionsAuth';
import CodeComponent from '../components/CodeComponent';

const mapStateToProps = ({
  reducerMain: {
    selectedPhone,
  },
  reducerAuth: {
    sendSms: {
      isLoading: isLoadingPhone,
    },
    sendCode: {
      isLoading: isLoadingCode, error, success,
    },
  },
}) => ({
  isLoadingCode,
  isLoadingPhone,
  error,
  success,
  selectedPhone,
});

const mapActionToProps = { sendCode: postSendCodeRequest, sendSms: postSendSmsRequest, nextStep: setIncrementStep };

export default connect(mapStateToProps, mapActionToProps)(CodeComponent);

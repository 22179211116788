import { setTokenToHeaders, sendSms, sendCode } from '../utils/serviceREST';
import { setGlobalErr } from './actionsErr';
import errorCodes from '../../config/errorCodes.json';

export const SET_REQUEST_SEND_SMS = 'SET_REQUEST_SEND_SMS';
export const SET_REQUEST_SEND_SMS_ERROR = 'SET_REQUEST_SEND_SMS_ERROR';
export const SET_REQUEST_SEND_SMS_SUCCESS = 'SET_REQUEST_SEND_SMS_SUCCESS';
export const SET_REQUEST_SEND_CODE = 'SET_REQUEST_SEND_CODE';
export const SET_REQUEST_SEND_CODE_ERROR = 'SET_REQUEST_SEND_CODE_ERROR';
export const SET_REQUEST_SEND_CODE_SUCCESS = 'SET_REQUEST_SEND_CODE_SUCCESS';

export const setRequestSendSms = () => ({ type: SET_REQUEST_SEND_SMS });
export const setRequestSendSmsError = data => ({ type: SET_REQUEST_SEND_SMS_ERROR, data });
export const setRequestSendSmsSuccess = data => ({ type: SET_REQUEST_SEND_SMS_SUCCESS, data });
export const setRequestSendCode = () => ({ type: SET_REQUEST_SEND_CODE });
export const setRequestSendCodeError = data => ({ type: SET_REQUEST_SEND_CODE_ERROR, data });
export const setRequestSendCodeSuccess = data => ({ type: SET_REQUEST_SEND_CODE_SUCCESS, data });

export const postSendSmsRequest = (phone = null) => async (dispatch) => {
  dispatch(setRequestSendSms());
  const result = await sendSms({ phone });
  const { data, status } = result || {};
  if (status === 200 && data?.statusCode === 0) {
    dispatch(setRequestSendSmsSuccess(phone));
    return;
  }
  dispatch(setGlobalErr({
    message: data?.statusMessage,
    action: 'postSendSmsRequest',
    params: [phone],
  }));
  dispatch(setRequestSendSmsError(data?.statusMessage));
};
export const postSendCodeRequest = (phone = null, code = null) => async (dispatch) => {
  dispatch(setRequestSendCode());
  const result = await sendCode({ phone, code });
  const { data, status } = result || {};
  if (status === 200 && data?.statusCode === 0) {
    const { token } = data?.response;
    if (token) {
      setTokenToHeaders(token);
    }
    dispatch(setRequestSendCodeSuccess(data?.response));
    return;
  }
  if (data?.statusCode !== errorCodes?.smsCodeExpired?.code) {
    dispatch(setGlobalErr({
      message: data?.statusMessage,
      action: 'postSendCodeRequest',
      params: [phone, code],
    }));
  }
  dispatch(setRequestSendCodeError(data?.statusMessage));
};

import { createOrder } from '../utils/serviceREST';
import { setGlobalErr } from './actionsErr';

export const SET_REQUEST_ORDER = 'SET_REQUEST_ORDER';
export const SET_REQUEST_ORDER_ERROR = 'SET_REQUEST_ORDER_ERROR';
export const SET_REQUEST_ORDER_SUCCESS = 'SET_REQUEST_ORDER_SUCCESS';

export const setRequestOrder = () => ({ type: SET_REQUEST_ORDER });
export const setRequestOrderError = data => ({ type: SET_REQUEST_ORDER_ERROR, data });
export const setRequestOrderSuccess = data => ({ type: SET_REQUEST_ORDER_SUCCESS, data });

export const postOrderRequest = (params = null) => async (dispatch) => {
  dispatch(setRequestOrder());
  const result = await createOrder(params);
  const { data, status } = result || {};
  if (status === 200 && data?.statusCode === 0) {
    dispatch(setRequestOrderSuccess(data?.response));
    return;
  }
  dispatch(setGlobalErr({
    message: data?.statusMessage,
    action: 'postOrderRequest',
    params: [params],
  }));
  dispatch(setRequestOrderError(data?.statusMessage));
};

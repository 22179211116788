import React from 'react';
import PT from 'prop-types';
import Cookies from 'js-cookie';

const getItemClassName = (i, j) => {
  const className = ['item'];
  if (i > j) {
    className.push('prev');
  }
  if (i === j) {
    className.push('active');
  }
  return className.join(' ');
};

const StepsHeaderComponent = ({
  resetStep,
  currentStep,
  steps = [],
}) => {
  let accum = 0;
  return (
    <div className="steps-wrapper">
      {steps.map((row, i) => currentStep >= accum && (
        <div className="row" key={i}>
          <div className="col-xs-12 steps">
            {row.map((step, j) => (
              <div
                key={j}
                onClick={() => {
                  if (currentStep > step.index) {
                    if (step.auth) {
                      Cookies.remove('token');
                    }
                    resetStep(step.index);
                  }
                }}
                className={getItemClassName(currentStep, accum++)} // eslint-disable-line
              >
                <i className={`icon icon-${step.icon}`}></i>
                <span>{ step.name }</span>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

StepsHeaderComponent.propTypes = {
  resetStep: PT.func.isRequired,
  currentStep: PT.number.isRequired,
  steps: PT.arrayOf(
    PT.arrayOf(
      PT.shape({
        icon: PT.string,
        name: PT.string,
      }),
    ),
  ),
};

export default StepsHeaderComponent;

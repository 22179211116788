import * as types from '../actions/actionsAuth';

export const initialState = {
  sendSms: {
    isLoading: false,
    error: '',
    success: false,
  },
  sendCode: {
    isLoading: false,
    error: '',
    success: false,
  },
};

const reducerAuth = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_REQUEST_SEND_SMS:
      return {
        ...initialState,
        sendSms: {
          ...initialState.sendSms,
          isLoading: true,
        },
      };
    case types.SET_REQUEST_SEND_SMS_ERROR:
      return {
        ...state,
        sendSms: {
          ...state.sendSms,
          error: action.data,
          isLoading: false,
        },
      };
    case types.SET_REQUEST_SEND_SMS_SUCCESS:
      return {
        ...state,
        sendSms: {
          ...state.sendSms,
          isLoading: false,
          success: true,
        },
      };
    case types.SET_REQUEST_SEND_CODE:
      return {
        ...state,
        sendCode: {
          ...initialState.sendCode,
          isLoading: true,
        },
      };
    case types.SET_REQUEST_SEND_CODE_ERROR:
      return {
        ...state,
        sendCode: {
          ...state.sendCode,
          error: action.data,
          isLoading: false,
        },
      };
    case types.SET_REQUEST_SEND_CODE_SUCCESS:
      return {
        ...state,
        sendCode: {
          ...state.sendCode,
          isLoading: false,
          success: true,
        },
      };
    default:
      return state;
  }
};

export default reducerAuth;

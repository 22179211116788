import dayjs from 'dayjs';
import 'dayjs/locale/ru';

dayjs.locale('ru');

export default format => (val) => {
  const flag = format.indexOf('MMMMM') !== -1;
  if (!flag) {
    return dayjs(val).format(format);
  }
  const pos = format.indexOf('MMMMM');
  let newFormat = format.replace('MMMMM', '');
  let month = dayjs(val).format('MMMM');
  const lastChar = month.charAt(month.length - 1);
  if (lastChar === 'т') {
    month += 'а';
  } else {
    month = `${month.slice(0, month.length - 1)}я`;
  }
  newFormat = newFormat.slice(0, pos) + month + newFormat.slice(pos, newFormat.length);
  return dayjs(val).format(newFormat);
};

export const parseDate = (val, toDate = true) => {
  const date = val.split('.');
  if (date.length < 3) {
    if (toDate) {
      return new Date(1970, 0, 1);
    }
    return dayjs().set('year', 1970).set('month', 0).set('date', 1);
  }
  const returnVal = dayjs(`${date[2]}-${parseInt(date[1], 10)}-${parseInt(date[0], 10)}`);

  if (toDate) {
    return returnVal.toDate();
  }
  return returnVal;
};

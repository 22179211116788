import { connect } from 'react-redux';
import { setResetStep } from '../actions/actionsMain';
import { getDepartmentsRequest } from '../actions/actionsDepartments';
import { getDepartmentChildrenRequest } from '../actions/actionsDirections';
import { getServicesRequest } from '../actions/actionsServices';
import { getTimetablesRequest } from '../actions/actionsTimetables';
import { postSendSmsRequest, postSendCodeRequest } from '../actions/actionsAuth';
import { getUsersRequest } from '../actions/actionsUsers';
import { postCreateUserRequest } from '../actions/actionsReg';
import { postOrderRequest } from '../actions/actionsOrder';
import { resetGlobalErr } from '../actions/actionsErr';
import ErrorHandlerComponent from '../components/ErrorHandlerComponent';

const mapStateToProps = ({
  reducerMain: { currentStep },
  reducerErr: { message, action, params },
}) => ({
  currentStep, message, action, params,
});

const mapActionToProps = {
  resetErr: resetGlobalErr,
  resetStep: setResetStep,
  postCreateUserRequest,
  getTimetablesRequest,
  postSendSmsRequest,
  postSendCodeRequest,
  postOrderRequest,
  getUsersRequest,
  getServicesRequest,
  getDepartmentChildrenRequest,
  getDepartmentsRequest,
};

export default connect(mapStateToProps, mapActionToProps)(ErrorHandlerComponent);

import React from 'react';
import { BarLoader } from 'react-spinners';
import colors from '../../constants/colors';

const LoaderComponent = () => (
  <BarLoader
    widthUnit="%"
    width={100}
    color={colors.blue}
  />
);

export default LoaderComponent;

import React from 'react';
import { Provider } from 'react-redux';

import store from './store';
import MainContainer from './containers/MainContainer';
import './styles/icomoon.css';
import './styles/index.scss';

const App = () => (
    <div>
      <Provider store={store}>
        <MainContainer />
      </Provider>
    </div>
);

export default App;

import React from 'react';
import PT from 'prop-types';

const SelectUserComponent = ({
  users,
  onClick,
  onAdd,
}) => (
  <div className="appointment-content appointment-form appointment-select-user">
    <span className="appointment-form-title">
      Выберите клиента для записи
    </span>
    <div className="appointment-select-user-items">
      {users.map(user => (
        <button className="btn appointment-user-btn" key={user.id} onClick={onClick(user)}>
          <i className="icon icon-user"></i>
          <span className="appointment-user-fio">{user.f || ''} {user.i || ''} {user.o || ''}</span>
          <i className="icon icon-arrow"></i>
        </button>
      ))}
    </div>
    <button className="btn appointment-btn" onClick={onAdd}>Добавить клиента</button>
  </div>
);

SelectUserComponent.propTypes = {
  users: PT.array.isRequired,
  onClick: PT.func.isRequired,
  onAdd: PT.func.isRequired,
};

export default SelectUserComponent;

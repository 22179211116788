import React, { Component } from 'react';
import PT from 'prop-types';
import SelectBoxComponent from './common/SelectBoxComponent';
import LoaderComponent from './common/LoaderComponent';

class DirectionComponent extends Component {
  componentDidMount() {
    const { getDirections, selectedDepartment, selectedDirection } = this.props;
    if (selectedDepartment?.id && !selectedDirection) {
      const { radId } = selectedDepartment;
      getDirections(radId);
    }
  }

  resetStep = () => {
    this.props.resetStep(1);
  }

  render() {
    const {
      isLoading,
      directions,
      selectedDirection,
      setDirection,
    } = this.props;

    if (isLoading) {
      return (<LoaderComponent />);
    }

    return (
      <div className="direction-select-box">
        <SelectBoxComponent
          icon="sheet"
          title="Направление"
          selected={selectedDirection}
          items={directions}
          onSelect={setDirection}
          onClickSelected={this.resetStep}
        />
      </div>
    );
  }
}

DirectionComponent.propTypes = {
  isLoading: PT.bool.isRequired,
  getDirections: PT.func.isRequired,
  resetStep: PT.func.isRequired,
  directions: PT.array.isRequired,
  setDirection: PT.func.isRequired,
  selectedDepartment: PT.object,
  selectedDirection: PT.object,
};
DirectionComponent.defaultProps = {
  selectedDepartment: null,
  selectedDirection: null,
};

export default DirectionComponent;

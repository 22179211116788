import React from 'react';
import PT from 'prop-types';
import CSSTransitionGroup from 'react-addons-css-transition-group';
import Calendar from 'react-calendar';
import dayjs from 'dayjs';
import { min, max } from 'lodash';
import formatMonthYear from '../utils/formatMonthYear';
import LoaderComponent from './common/LoaderComponent';

class DateComponent extends React.Component {
  state = {
    minDate: new Date(),
    maxDate: new Date(),
  }

  static getDerivedStateFromProps(nextProps) {
    const dates = nextProps.dates.map(item => dayjs(item));
    const now = new Date();
    let minDate = min(dates)?.toDate() || now;
    if (minDate < now) {
      minDate = now;
    }
    let maxDate = max(dates)?.toDate() || now;
    if (maxDate < minDate) {
      maxDate = minDate;
    }
    return { minDate, maxDate };
  }

  componentDidMount() {
    const {
      getTimetables,
      selectedService,
      selectedDate,
    } = this.props;
    if (!selectedDate) {
      getTimetables(selectedService?.radId, selectedService?.duration);
    }
  }

  checkDateDisabled = ({ date }) => {
    const dates = this.props.dates.map(item => dayjs(item));
    const dateObj = dayjs(dayjs(date).format('YYYY-MM-DD')); // .format is to reset time in date
    const condition = dates.some(item => item.isSame(dateObj));

    return !condition;
  }

  resetStep = () => {
    if (this.props.selectedDate) {
      this.props.resetStep(3);
    }
  }

  render() {
    const {
      setDate,
      isLoading,
      dates,
      selectedDate,
    } = this.props;

    const { minDate, maxDate } = this.state;

    if (isLoading) {
      return (<LoaderComponent />);
    }

    if (!dates?.length) {
      return (
        <div className="date-select-box">
        <div className={`select-box ${selectedDate && 'select-box-active'}`}>
          <div className="appointment-content">
            <div className="select-box-items appointment-content">
              <div className="select-box-item" style={{ cursor: 'initial' }}>
                Для записи на данную услугу нет свободных часов приема. <br/>
                Пожалуйста, повторите попытку завтра
              </div>
            </div>
          </div>
        </div>
        </div>
      );
    }

    if (selectedDate) {
      return (
        <div className="date-select-box">
        <div className={`select-box ${selectedDate && 'select-box-active'}`}>
          <div className="select-box-title select-box-active" onClick={this.resetStep}>
            <i className="select-box-icon icon icon-calendar"></i>
            <span className="select-box-name">{formatMonthYear('DD MMMMM YYYY')(selectedDate)}</span>
          </div>
        </div>
        </div>
      );
    }

    return (
        <div className="date-select-box">
      <div className={`select-box ${selectedDate && 'select-box-active'}`}>
        <div className="select-box-title">
          <i className="select-box-icon icon icon-calendar"></i>
          <span className="select-box-name">{ selectedDate || 'Дата' }</span>
        </div>
        <div className="appointment-content-wrapper">
          <CSSTransitionGroup
            transitionName="appointment-slide"
            transitionAppear={true}
            transitionAppearTimeout={500}
            transitionEnterTimeout={500}
            transitionLeaveTimeout={300}
          >
            <div className="appointment-content">
              <Calendar
                onChange={setDate}
                prevLabel={<i className="icon icon-arrow"></i>}
                nextLabel={<i className="icon icon-arrow"></i>}
                tileDisabled={this.checkDateDisabled}
                minDate={minDate}
                maxDate={maxDate}
                minDetail="month"
                maxDetail="month"
                formatMonthYear={formatMonthYear('MMMM YYYY')}
              />
            </div>
          </CSSTransitionGroup>
        </div>
      </div>
      </div>
    );
  }
}

DateComponent.propTypes = {
  isLoading: PT.bool.isRequired,
  setDate: PT.func.isRequired,
  getTimetables: PT.func.isRequired,
  resetStep: PT.func.isRequired,
  selectedDirection: PT.object,
  selectedService: PT.object,
  selectedDate: PT.instanceOf(Date),
  dates: PT.array,
};
DateComponent.defaultProps = {
  selectedDirection: null,
  selectedDate: null,
};

export default DateComponent;

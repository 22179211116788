import React, { Component } from 'react';
import PT from 'prop-types';
import { Formik } from 'formik';
import MaskedInput from 'react-input-mask';
import LoaderComponent from './common/LoaderComponent';
import apiConfig from '../constants/api';

const { attemtsExpire } = apiConfig;

const codeMask = /\d{4}/;
class CodeComponent extends Component {
  state = {
    success: false,
    sendAgain: false,
  };

  initialValues = {
    appointmentCode: '',
  };

  timer = null;

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.success !== prevState.success) {
      return { success: nextProps.success };
    }
    return null;
  }

  componentDidMount() {
    this.setTimer();
  }

  componentWillUnmount() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.success && this.state.success) {
      this.props.nextStep();
    }
  }

  setTimer = () => {
    this.setState({
      sendAgain: false,
    }, () => {
      this.timer = setTimeout(() => {
        this.setState({
          sendAgain: true,
        });
      }, attemtsExpire * 60 * 1000);
    });
  }

  sendAgain = () => {
    this.setTimer();
    const { selectedPhone } = this.props;
    this.props.sendSms(selectedPhone);
  }

  validate = (values) => {
    const errors = {};
    if (!values.appointmentCode) {
      errors.appointmentCode = 'Заполните поле';
    } else if (!codeMask.test(values.appointmentCode)) {
      errors.appointmentCode = 'Код должен состоять из 4 символов';
    }
    return errors;
  }

  onSubmit = (values) => {
    const { selectedPhone } = this.props;
    this.props.sendCode(selectedPhone, values.appointmentCode);
  }

  render() {
    const { sendAgain } = this.state;
    const { isLoadingPhone, isLoadingCode, error } = this.props;

    return (
      <Formik
        initialValues={this.initialValues}
        validate={this.validate}
        onSubmit={this.onSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          resetForm,
        }) => (
          <form
          className="appointment-content appointment-form appointment-auth-code" onSubmit={handleSubmit}>
            {(isLoadingCode || isLoadingPhone) && (<LoaderComponent />)}
            <span className="appointment-form-title">
              Для подтверждения номера введите SMS - код
            </span>
              <div className="appointment-form-group">
                {error !== '' && (<span className="appointment-errors">{ error }</span>)}
                {errors?.appointmentCode && touched?.appointmentCode ? (
                  <span className="appointment-errors">{errors?.appointmentCode}</span>
                ) : (
                  <label htmlFor="appointmentCode" className="appointment-label">SMS - код</label>
                )}
                <div className="appointment-input">
                  <i className="icon icon-message"></i>
                  <MaskedInput
                    mask="9999"
                    type="text"
                    id="appointmentCode"
                    name="appointmentCode"
                    onFocus={() => {
                      resetForm();
                    }}
                    onChange={handleChange}
                    value={values.appointmentCode} />
                </div>
              </div>
              {sendAgain && (
                <a href="#" onClick={this.sendAgain} className="appointment-link">Отправить код повторно</a>
              )}
              <input
                className="btn appointment-btn"
                type="submit"
                disabled={isLoadingCode || isLoadingPhone}
                value="Далее" />
          </form>
        )}
      </Formik>
    );
  }
}

CodeComponent.propTypes = {
  isLoadingPhone: PT.bool.isRequired,
  isLoadingCode: PT.bool.isRequired,
  error: PT.string.isRequired,
  success: PT.bool.isRequired,
  selectedPhone: PT.string,
  nextStep: PT.func.isRequired,
  sendSms: PT.func.isRequired,
  sendCode: PT.func.isRequired,
};

CodeComponent.defaultProps = {
  selectedPhone: null,
};

export default CodeComponent;

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Logo from '../../public/logo.png';

const style = {
  alignItems: 'center',
  justifyContent: 'center',
};

const WelcomeComponent = ({
  startAppointment,
}) => (
  <Fragment>
    <div className="row">
      <div className="col-xs-12">
        <span className="appointment-title">Записаться на прием</span>
      </div>
    </div>
    <div className="appointment-modal" style={style}>
      <div className="appointment-logo">
        <img src={Logo} alt="" />
      </div>
      <span className="appointment-greetings">Мы рады приветствовать Вас в электронной<br />
        регистратуре медицинского центра &laquo;Радужный&raquo;</span>
      <div className="appointment-greetings-buttons">
        <span className="choose-label">Выберите тип записи</span>
        <button className="btn appointment-btn" onClick={startAppointment}>Записаться на платные услуги</button>
        <a className="btn appointment-btn" href={'https://www.gosuslugi.ru/10066/1/form'} rel="noopener noreferrer" target="_blank">Записаться по ОМС</a>
      </div>
    </div>
  </Fragment>
);

WelcomeComponent.propTypes = {
  startAppointment: PropTypes.func.isRequired,
};

export default WelcomeComponent;

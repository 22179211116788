import { getTimetable } from '../utils/serviceREST';
import { setGlobalErr } from './actionsErr';

export const SET_REQUEST_TIMETABLES = 'SET_REQUEST_TIMETABLES';
export const SET_REQUEST_TIMETABLES_ERROR = 'SET_REQUEST_TIMETABLES_ERROR';
export const SET_REQUEST_TIMETABLES_SUCCESS = 'SET_REQUEST_TIMETABLES_SUCCESS';

export const setRequestTimetables = () => ({ type: SET_REQUEST_TIMETABLES });
export const setRequestTimetablesError = data => ({ type: SET_REQUEST_TIMETABLES_ERROR, data });
export const setRequestTimetablesSuccess = data => ({ type: SET_REQUEST_TIMETABLES_SUCCESS, data });

export const getTimetablesRequest = (serviceId = null, serviceDur = null) => async (dispatch) => {
  dispatch(setRequestTimetables());
  const result = await getTimetable({ serviceId, serviceDur });
  const { data, status } = result || {};
  if (status === 200 && data?.statusCode === 0) {
    dispatch(setRequestTimetablesSuccess(data?.response));
    return;
  }
  dispatch(setGlobalErr({
    message: data?.statusMessage,
    action: 'getTimetablesRequest',
    params: [serviceId, serviceDur],
  }));
  dispatch(setRequestTimetablesError(data?.statusMessage));
};

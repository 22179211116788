import { connect } from 'react-redux';
import { getServicesRequest } from '../actions/actionsServices';
import { setSelectedService, setResetStep } from '../actions/actionsMain';
import ServiceComponent from '../components/ServiceComponent';

const mapStateToProps = ({
  reducerMain: { selectedDirection, selectedService },
  reducerServices: { isLoading, services },
}) => ({
  isLoading,
  services,
  selectedDirection,
  selectedService,
});

const mapActionToProps = { getServices: getServicesRequest, setService: setSelectedService, resetStep: setResetStep };

export default connect(mapStateToProps, mapActionToProps)(ServiceComponent);

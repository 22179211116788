import { getUsers } from '../utils/serviceREST';
import { setGlobalErr } from './actionsErr';

export const SET_REQUEST_USERS = 'SET_REQUEST_USERS';
export const SET_REQUEST_USERS_ERROR = 'SET_REQUEST_USERS_ERROR';
export const SET_REQUEST_USERS_SUCCESS = 'SET_REQUEST_USERS_SUCCESS';

export const setRequestUsers = () => ({ type: SET_REQUEST_USERS });
export const setRequestUsersError = data => ({ type: SET_REQUEST_USERS_ERROR, data });
export const setRequestUsersSuccess = data => ({ type: SET_REQUEST_USERS_SUCCESS, data });

export const getUsersRequest = () => async (dispatch) => {
  dispatch(setRequestUsers());
  const result = await getUsers();
  const { data, status } = result || {};
  if (status === 200 && data?.statusCode === 0) {
    dispatch(setRequestUsersSuccess(data?.response));
    return;
  }
  dispatch(setGlobalErr({
    message: data?.statusMessage,
    action: 'getUsersRequest',
    params: [],
  }));
  dispatch(setRequestUsersError(data?.statusMessage));
};

import { getServices } from '../utils/serviceREST';
import { setGlobalErr } from './actionsErr';

export const SET_REQUEST_SERVICES = 'SET_REQUEST_SERVICES';
export const SET_REQUEST_SERVICES_ERROR = 'SET_REQUEST_SERVICES_ERROR';
export const SET_REQUEST_SERVICES_SUCCESS = 'SET_REQUEST_SERVICES_SUCCESS';

export const setRequestServices = () => ({ type: SET_REQUEST_SERVICES });
export const setRequestServicesError = data => ({ type: SET_REQUEST_SERVICES_ERROR, data });
export const setRequestServicesSuccess = data => ({ type: SET_REQUEST_SERVICES_SUCCESS, data });

export const getServicesRequest = (departmentId = null) => async (dispatch) => {
  dispatch(setRequestServices());
  const result = await getServices({ departmentId });
  const { data, status } = result || {};
  if (status === 200 && data?.statusCode === 0) {
    dispatch(setRequestServicesSuccess(data?.response));
    return;
  }
  dispatch(setGlobalErr({
    message: data?.statusMessage,
    action: 'getServicesRequest',
    params: [departmentId],
  }));
  dispatch(setRequestServicesError(data?.statusMessage));
};

import React, { Component } from 'react';
import PT from 'prop-types';
import SelectBoxComponent from './common/SelectBoxComponent';
import LoaderComponent from './common/LoaderComponent';

class ServiceComponent extends Component {
  componentDidMount() {
    const { getServices, selectedDirection, selectedService } = this.props;
    if (selectedDirection?.id && !selectedService) {
      const { radId } = selectedDirection;
      getServices(radId);
    }
  }

  resetStep = () => {
    this.props.resetStep(2);
  }

  render() {
    const {
      isLoading,
      services,
      selectedService,
      setService,
    } = this.props;

    if (isLoading) {
      return (<LoaderComponent />);
    }

    return (
      <div className="service-select-box">
        <SelectBoxComponent
          icon="heart"
          title="Услуга"
          showSearch={services.length > 11}
          selected={selectedService}
          items={services}
          onSelect={setService}
          onClickSelected={this.resetStep}
        />
      </div>
    );
  }
}

ServiceComponent.propTypes = {
  isLoading: PT.bool.isRequired,
  getServices: PT.func.isRequired,
  resetStep: PT.func.isRequired,
  services: PT.array.isRequired,
  setService: PT.func.isRequired,
  selectedDirection: PT.object,
  selectedService: PT.object,
};
ServiceComponent.defaultProps = {
  selectedDirection: null,
  selectedService: null,
};

export default ServiceComponent;

import React, { Component } from 'react';
import PT from 'prop-types';
import dayjs from 'dayjs';
import { identity } from 'lodash';

import FormGroup from './common/FormGroup';
import LoaderComponent from './common/LoaderComponent';
import formatMonthYear from '../utils/formatMonthYear';

class OrderComponent extends Component {
  state = {
    success: false,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.success !== prevState.success) {
      return { success: nextProps.success };
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.success && this.state.success) {
      this.props.nextStep();
    }
  }

  onSubmit = () => {
    const {
      selectedService,
      selectedDoctor,
      selectedUser,
      selectedDate,
      selectedTime,
      createOrder,
    } = this.props;

    const time = selectedTime?.time?.split(':') || [];
    const date = dayjs(selectedDate)
      .set('hour', time[0])
      .set('minute', time[1])
      .set('second', time[2])
      .format('YYYY-MM-DD HH:mm:ss');

    createOrder({
      clientId: selectedUser.id,
      doctorId: selectedDoctor.id,
      serviceId: selectedService.radId,
      date,
    });
  }

  render() {
    const {
      isLoading,
      selectedDepartment,
      selectedDirection,
      selectedService,
      selectedDate,
      selectedTime,
      selectedDoctor,
      selectedUser,
    } = this.props;
    return (
      <div className="appointment-content appointment-form appointment-add-order">
        {isLoading && (<LoaderComponent />)}
        <FormGroup
          name="selectedDepartment"
          label="Отделение"
          icon="stethoscope"
          onChange={identity}
        >
          {selectedDepartment.name}
        </FormGroup>
        <FormGroup
          name="selectedDirection"
          label="Направление"
          icon="sheet"
          onChange={identity}
        >
          {selectedDirection.name}
        </FormGroup>
        <FormGroup
          name="selectedService"
          label="Услуга"
          icon="heart"
          onChange={identity}
        >
          {selectedService.name}
        </FormGroup>
        <div className="form-group-row">
          <FormGroup
            name="selectedDate"
            label="Дата"
            icon="calendar"
            onChange={identity}
          >
            {formatMonthYear('DD MMMMM YYYY')(selectedDate)}
          </FormGroup>
          <FormGroup
            name="selectedTime"
            label="Время"
            icon="time"
            onChange={identity}
          >
            {selectedTime.time.slice(0, 5)}
          </FormGroup>
          <FormGroup
            name="selectedServicePrice"
            label="Стоимость"
            icon="rubl"
            onChange={identity}
          >
            <span>{selectedService.price} <i className="icon icon-rouble"></i></span>
          </FormGroup>
        </div>
        <div className="form-group-row">
          <FormGroup
            name="selectedDoctor"
            label="Врач"
            icon="doctor"
            onChange={identity}
          >
            {selectedDoctor.name}
          </FormGroup>
          <FormGroup
            name="selectedUser"
            label="Клиент"
            icon="user"
            onChange={identity}
          >
            {`${selectedUser.f} ${selectedUser.i} ${selectedUser.o}`}
          </FormGroup>
        </div>
        <button className="btn appointment-btn" disabled={isLoading} onClick={this.onSubmit}>Записаться</button>
      </div>
    );
  }
}

OrderComponent.propTypes = {
  createOrder: PT.func.isRequired,
  nextStep: PT.func.isRequired,
  selectedDepartment: PT.object.isRequired,
  selectedDirection: PT.object.isRequired,
  selectedService: PT.object.isRequired,
  selectedDate: PT.string.isRequired,
  selectedTime: PT.object.isRequired,
  selectedDoctor: PT.object.isRequired,
  selectedUser: PT.object.isRequired,
  isLoading: PT.bool.isRequired,
  success: PT.bool.isRequired,
};

export default OrderComponent;

import React, { Component } from 'react';
import PT from 'prop-types';
import { Formik } from 'formik';
import dayjs from 'dayjs';

import FormGroup from './common/FormGroup';
import DatePickerFG from './common/DatePickerFG';
import { parseDate } from '../utils/formatMonthYear';

class AddUserComponent extends Component {
  initialValues = {
    appointmentSurname: '',
    appointmentName: '',
    appointmentLastname: '',
    appointmentBirthday: '',
  }

  validate = (values) => {
    const errors = {};
    const {
      appointmentSurname,
      appointmentName,
      appointmentLastname,
      appointmentBirthday,
    } = values;
    if (!appointmentSurname) {
      errors.appointmentSurname = 'Введите фамилию';
    } else if (appointmentSurname.length < 2 && appointmentSurname.length > 55) {
      errors.appointmentSurname = 'Фамилия не должно быть меньше 2 символов и больше 55 символов';
    }
    if (!appointmentName) {
      errors.appointmentName = 'Введите имя';
    } else if (appointmentName.length < 2 && appointmentName.length > 55) {
      errors.appointmentName = 'Имя не должно быть меньше 2 символов и больше 55 символов';
    }
    if (!appointmentLastname) {
      errors.appointmentLastname = 'Введите отчество';
    } else if (appointmentLastname.length < 2 && appointmentLastname.length > 55) {
      errors.appointmentLastname = 'Отчество не должно быть меньше 2 символов и больше 55 символов';
    }
    const parsedDate = parseDate(appointmentBirthday, false);
    const splittedDate = appointmentBirthday ? appointmentBirthday.split('.') : [];
    if (!appointmentBirthday) {
      errors.appointmentBirthday = 'Введите дату рождения';
    } else if (!parsedDate.isValid()
      || parsedDate.date() !== parseInt(splittedDate[0], 10)
      || parsedDate.month() + 1 !== parseInt(splittedDate[1], 10)) {
      errors.appointmentBirthday = 'Введите дату рождения в формате ДД.ММ.ГГГГ';
    } else if (parsedDate.isAfter(dayjs())
      || parsedDate.isBefore(dayjs('1900-01-01'))) {
      errors.appointmentBirthday = `Введите дату рождения в промежутке между 01.01.1900 и ${dayjs().format('DD.MM.YYYY')}`;
    }
    return errors;
  }

  render() {
    const { isLoading, onNext, onClickBack } = this.props;
    return (
      <Formik
        initialValues={this.initialValues}
        validate={this.validate}
        onSubmit={onNext}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <form className="appointment-content appointment-form appointment-add-user" onSubmit={handleSubmit}>
            <span className="appointment-form-title">
              Заполните личные данные
            </span>
            <FormGroup
              error={errors.appointmentSurname}
              touched={touched.appointmentSurname}
              value={values.appointmentSurname}
              name="appointmentSurname"
              label={'Фамилия'}
              onChange={handleChange}
            />
            <FormGroup
              error={errors.appointmentName}
              touched={touched.appointmentName}
              value={values.appointmentName}
              name="appointmentName"
              label={'Имя'}
              onChange={handleChange}
            />
            <FormGroup
              error={errors.appointmentLastname}
              touched={touched.appointmentLastname}
              value={values.appointmentLastname}
              name="appointmentLastname"
              label={'Отчество'}
              onChange={handleChange}
            />
            <DatePickerFG
              error={errors.appointmentBirthday}
              touched={touched.appointmentBirthday}
              value={values.appointmentBirthday}
              name="appointmentBirthday"
              label={'Дата рождения'}
              onChangeText={handleChange}
              onChangeDate={setFieldValue}
            />
            <input type="submit" value="Далее" className="btn appointment-btn" disabled={isLoading} />
            <a href="#" className="appointment-link" onClick={onClickBack}>Выбрать клиента</a>
          </form>
        )}
      </Formik>
    );
  }
}

AddUserComponent.propTypes = {
  onNext: PT.func.isRequired,
  onClickBack: PT.func.isRequired,
  isLoading: PT.bool.isRequired,
};

export default AddUserComponent;

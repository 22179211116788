import React from 'react';
import PT from 'prop-types';
import SelectBoxComponent from './common/SelectBoxComponent';

const TimeComponent = ({
  doctors,
  setDoctor,
  selectedDoctor,
  resetStep,
}) => (
    <div className="department-select-box">
      <SelectBoxComponent
        icon="doctor"
        title="Врач"
        showSearch={doctors.length > 11}
        selected={selectedDoctor}
        items={doctors}
        onSelect={setDoctor}
        onClickSelected={() => {
          if (selectedDoctor) {
            resetStep(5);
          }
        }}
      />
    </div>
);

TimeComponent.propTypes = {
  resetStep: PT.func.isRequired,
  setDoctor: PT.func.isRequired,
  doctors: PT.array.isRequired,
  selectedDoctor: PT.object,
};
TimeComponent.defaultProps = {
  selectedDoctor: null,
};

export default TimeComponent;

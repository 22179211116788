import { connect } from 'react-redux';
import { setSelectedTime, setResetStep } from '../actions/actionsMain';
import TimeComponent from '../components/TimeComponent';

const mapStateToProps = ({
  reducerMain: { selectedTime },
  reducerTimetables: { times },
}) => ({
  selectedTime,
  times,
});

const mapActionToProps = { setTime: setSelectedTime, resetStep: setResetStep };

export default connect(mapStateToProps, mapActionToProps)(TimeComponent);

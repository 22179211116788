import { combineReducers } from 'redux';

import reducerMain from './reducerMain';
import reducerDepartments from './reducerDepartments';
import reducerDirections from './reducerDirections';
import reducerServices from './reducerServices';
import reducerTimetables from './reducerTimetables';
import reducerAuth from './reducerAuth';
import reducerReg from './reducerReg';
import reducerUsers from './reducerUsers';
import reducerOrder from './reducerOrder';
import reducerErr from './reducerErr';

const reducers = {
  reducerMain,
  reducerDepartments,
  reducerDirections,
  reducerServices,
  reducerTimetables,
  reducerAuth,
  reducerReg,
  reducerUsers,
  reducerOrder,
  reducerErr,
};

const appReducer = combineReducers(reducers);

const rootReducer = (state, action) => appReducer(state, action);

export default rootReducer;
